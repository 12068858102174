.coinsBox {
    background-color: rgb(106, 107, 44);
    min-width: 300px;
    text-align: center;
}

.fishingBox {
    background-color: rgb(37, 63, 87);
    min-width: 300px;
    text-align: center;
}


.neptuneBox {
    background-color: rgb(145, 0, 150);
    min-width: 300px;
    text-align: center;
}

.posidonBox {
    background-color: rgb(0, 124, 161);
    min-width: 300px;
    text-align: center;
}


.secondSet {
    margin-top: 10px;

}

.thirdSet {
    margin-top: 10px;

}

.thievingBox {
    background-color: rgb(45, 16, 70);
    min-width: 300px;
    text-align: center;
}

.craftingBox {
    background-color: rgb(63, 46, 36);
    min-width: 300px;
    text-align: center;
}

.totalBox {
    background-color: rgb(85, 129, 105);
    min-width: 300px;
    text-align: center;
}

.colBox {
    background-color: rgb(129, 85, 128);
    min-width: 300px;
    text-align: center;
}

.hsTitle {
    text-align: center;
    flex-grow: 1
}

.titleBg {
    background-color: rgba(0, 0, 0, 0.788);
}

.tableX {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.loady {
    margin-left: auto;
    margin-right: auto;
}

a {
    color: rgb(255, 255, 255);
}

a:link {
    color: rgb(255, 255, 255);
}

a:visited {
    color: rgb(255, 255, 255);
}

a:hover {
    color: rgb(255, 35, 171);
}

a:active {
    color: rgb(255, 255, 255);
}