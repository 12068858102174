.wikiOuterDiv{
    width: 96%;
    height: 96%;
    width: 100%;
    height: 98%
}

#wikiOuterDiv {
    width: 96%;
    height: 96%;
    width: 100%;
    height: 99%
}

.wikiInnerDiv{
    width: 100%;
    height: 100%

}