

@font-face {
    font-family: 'AdvoCut';
    src:url('/public/fonts/AdvoCut.ttf.woff') format('woff'),
        url('/public/fonts/AdvoCut.ttf.svg#AdvoCut') format('svg'),
        url('/public/fonts/AdvoCut.ttf.eot'),
        url('/public/fonts/AdvoCut.ttf.eot?#iefix') format('embedded-opentype'); 
    font-weight: bold;
    font-style: bold;
}

.theimg{
    width: 20px;
    height: 20px;
    margin-bottom: -9px;
}
.rowcontainer{
    width: 160%
}
.rowitem{
    width: 25%;
    display: flex;
    max-height: 5px;
    display: inline;
}

.brow {

}

.boldme {
    font-weight: bolder;
}

.rowsmall{
    width: 5%;
}

.rowmed{
    width: 10%;
}
.rowlarge{
    width: 25%;
}


.quality_white {
    color: rgb(199, 215, 223);
}
.quality_blue {
    color: rgb(100, 97, 255);
}
.quality_purple {
    color: rgb(165, 58, 252);
}
.quality_gold {
    color: rgb(240, 221, 53);
}
.quality_pink {
    color: rgb(199, 119, 159);
}

.priceColor_white {
    color: rgb(199, 215, 223);
    margin-left: 10px;
}
.priceColor_yellow {
    color: rgb(255, 242, 128);
    margin-left: 10px;
}
.priceColor_green {
    color: rgb(158, 238, 189);
    margin-left: 10px;
}
.priceColor_pink {
    color: rgb(253, 181, 217);
    margin-left: 10px;
}

.eachString {
    margin-left: 8px;
}

.strip {
    height: 25px;
    margin-bottom: 0px;
}


.rightSideOffset {
    margin-top: -8px;
    display: inline-block;
}