.inputSpyName {
  background-color: rgb(78, 78, 78);
}

.spyButton {
  margin-left: 4px !important;
}

.fishingStatsPanelContainer {
  background-color: rgb(37, 63, 87);
  min-width: 300px;
  margin: 8px;
}
.pageholder{

}


.sproo {
  font-size: x-small;
}
  
.pageholder2{
  padding: 12px;
}
.spyStatsStack {
  justify-content: center;
}
.bbg{
  background-color: aqua;
}
.thievingStatsPanelContainer {
  background-color: rgb(45, 16, 70);
  min-width: 300px;
  margin: 8px;
}

.craftingStatsPanelContainer {
  background-color: rgb(63, 46, 36);
  min-width: 300px;
  margin: 8px;
}

.anIcon {
}
.centerInputLabel {
  color: rgb(255, 255, 255, 0.4) !important;
  /* margin-top: -20px; */
  margin-top: 2px;
  font-size: x-small !important;
}

.rightSideOfStatPanel {
  margin-left: 7px !important;
  min-width: 300px;
  margin-right: 7px;
  margin-bottom: 7px;
}
