.statsContainer {
    display: inline-flex
}

.uiButton {
    padding: 2px;
}

.theTitle{
    width: 88%;
}