.activ {
    padding: 8px
}

.gebralta a {
    color: aquamarine !important;
}

.gebralta a:hover {
    color: rgb(114, 255, 27) !important;
}

.devavvy {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    background-color: blue;
    /* commented for demo
    float: left;
    margin-left: 125px;
    margin-top: 20px;
    */

    /*for demo*/
    display: inline-block;
    vertical-align: middle;
}

.ttx {
    height: 100px;

    font-size: small;
    color: #000000;
    margin-top: 300px;
}

.devDiv {

    height: 100px;
    display: inline-flex;
    font-size: small;
}

.theT {
    margin-top: 4px;
    color: #000000;
    padding-right: 7px;
    padding-left: 2px;
}

.devimg {
    width: 100%
}