.anImgOnPrices {
    margin-bottom: -18px;
    margin-top: 41px;
}

.allText {
    text-align: left!important;
    width: 700px;
    height: 50px;
}

.makeGreen{
    color: rgb(199, 226, 216);
}

.makeGold{
    color: rgb(248, 208, 30);
    text-shadow: rgba(255,162,0,0.9) 0px 0px 20px;
}

.goldRarity{
    color: rgb(248, 208, 30);
    text-shadow: rgba(255,162,0,0.9) 0px 0px 20px;
}

.purpleRarity {
    color: rgb(173, 101, 255);
    text-shadow: rgba(105, 40, 148, 0.9) 0px 0px 20px; 
}

.blueRarity {
    color: rgb(173, 186, 255);
    text-shadow: rgba(30, 36, 83, 0.9) 0px 0px 20px; 
}

.greenRarity {
    color: rgb(199, 226, 216);
}

.pinkRarity {
    color: rgb(248, 168, 224);
}


.theBy {
    color: rgb(171, 174, 173);
    font-size: smaller;
}