.inv {
  background-color: rgb(116, 102, 96);
  border: 3pxs;
  max-width: 250px;
  margin-top: 8px;
  margin-bottom: 20px;
}

.invTitle {
  text-align: center;
}

.theTitle{
  width: 60%;
}

.invTitleHolder {
  background-color: rgb(91, 78, 73);
}
.spanRow{
  width: 80%;
  height: 16px;
  font-size: 10px;
  line-height: -20px;

}


.spanRowT{
  margin-top: -2px;
  line-height: 0px;
  
}

.numOverlay {
  margin-top: 22px;
  color: gold;
  font-size: 14px;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  grid-column: 1;
  grid-row: 1;
  z-index: 9;
}
.pp {
  width: 50px;
  text-align: center;
}
.numOverlay2 {
  margin-top: 21px;
  margin-left: -1px;
  color: rgb(37, 36, 32);
  font-size: 14px;
  grid-column: 1;
  grid-row: 1;
  z-index: 8;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
}

.invBox {
}

.anItemBox {
  grid-column: 1;
  grid-row: 1;
  position: relative;
}

.invPanel {
  background-color: rgb(139, 117, 105);
  overflow: hidden!important;
}

.gridItemHolder {
  display: grid;
}
