

@font-face {
    font-family: 'AdvoCut';
    src:url('/public/fonts/AdvoCut.ttf.woff') format('woff'),
        url('/public/fonts/AdvoCut.ttf.svg#AdvoCut') format('svg'),
        url('/public/fonts/AdvoCut.ttf.eot'),
        url('/public/fonts/AdvoCut.ttf.eot?#iefix') format('embedded-opentype'); 
    font-weight: bold;
    font-style: bold;
}





.anItemBox1{
    width: 50px;
    height: 50px;
    position: absolute;
    margin-left: 19px;

    img {
        position: absolute;
    
    }

   

}

.stockLine
{
    margin-top: -6PX;
    color: rgb(148, 148, 148);
}

.aListingContainer {
    width: 346px;
    height: 76px;
    background: rgb(4,0,64);
    background: linear-gradient(90deg, rgba(4,0,64,1) 0%, rgba(9,9,121,1) 99%);
    font-family: 'AdvoCut';

    font-family: 'AdvoCut';
    /* font-family:monospace; */
    font-family: 'Verdana';
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 22px;
    font-weight: 530;
    /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */

}

.leftSideContainer {
 width: 86px;
 background-color: rgba(180, 180, 255, 0.87);
 font-family: 'AdvoCut';
 font-size: 26px;
}

.rightSideContainer {
 width: 248px;
 height: 72px;
 margin-left: 98px;
 text-shadow: 2px 2px 2px black;
 font-size: 26px;
}

.usernameStrip{
    color: rgb(192, 186, 179);
}

.quantityStrip {
    height: 26px;
    background-color: rgba(240, 248, 255, 0.199);
    color: rgb(0, 16, 39);
    margin-top: 50px;
    position: fixed;
    width: 90px;
    text-align: center;
    -webkit-text-stroke-width: thin;
}

.itemnameStrip {
    font-size: 30px;
    text-align: left;
   width: 190;
   

}

.dm{
    -webkit-transform:scale(0.5,1); /* Safari and Chrome */
    -moz-transform:scale(0.5,1); /* Firefox */
    -ms-transform:scale(0.5,1); /* IE 9 */
    -o-transform:scale(0.5,1); /* Opera */
    transform:scale(0.5,1); /* W3C */
}

.itemnameStrip p {

    display: inline-block;
    margin-top: -32px;
    margin-left: -2px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-self: stretch;
    width: 190;

}

.priceStrip {
    display: ruby;
    display: -webkit-inline-box;
    margin-top: 5px;
}

.quality_white {
    color: rgb(199, 215, 223);
}
.quality_blue {
    color: rgb(100, 97, 255);
}
.quality_purple {
    color: rgb(165, 58, 252);
}
.quality_gold {
    color: rgb(240, 221, 53);
}
.quality_pink {
    color: rgb(199, 119, 159);
}

.priceColor_white {
    color: rgb(199, 215, 223);
    margin-left: 10px;
}
.priceColor_yellow {
    color: rgb(255, 242, 128);
    margin-left: 10px;
}
.priceColor_green {
    color: rgb(158, 238, 189);
    margin-left: 10px;
}
.priceColor_pink {
    color: rgb(253, 181, 217);
    margin-left: 10px;
}

.eachString {
    margin-left: 8px;
}

.strip {
    height: 25px;
    margin-bottom: 0px;
}


.rightSideOffset {
    margin-top: -8px;
    display: inline-block;
    width: 120%;
}