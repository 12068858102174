.chat-container {
  width: 100%;
  height: 200px !important;
  overflow-y: scroll;
}

.chat-messages {
  padding: 1%;
}

.chat-message {
  margin-bottom: 1%;
}

.sender {
  font-weight: bold;
  color: #87E5E8;
}

.text {
  margin-left: 1%;
}

.timestamp {
  margin-left: 1%;
  margin-right: 1%;
  color: #AFAFAF;
  font-size: 0.8em;
}