.inv {
  background-color: rgb(116, 102, 96);
  border: 3pxs;
  max-width: 500px;
  margin-top: 8px;
  margin-bottom: 20px;
}

.quava{
  width: 100%;
}
.title80{
  width: 72.5%;
}

.juicer{
  width: 500px!important;
  display:block;
}

.spanRowT{
  margin-top: -2px;
  line-height: 0px;
  
}

.invTitle {
  text-align: center;
}

.invTitleHolder {
  background-color: rgb(91, 78, 73);
}

.bbg {
  background-color: rgb(139, 139, 218);
} 

.aColLogLista{
  width: 33%;
  font-size: 10px;
}

.graya{
  color: rgb(173, 173, 173);
}

.colFoundText {
  color: rgb(129, 238, 129);
}

.numOverlay {
  margin-top: 22px;
  color: gold;
  font-size: 14px;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  grid-column: 1;
  grid-row: 1;
  z-index: 9;
}
.pp {
  width: 50px;
  text-align: center;
}
.numOverlay2 {
  margin-top: 21px;
  margin-left: -1px;
  color: rgb(37, 36, 32);
  font-size: 14px;
  grid-column: 1;
  grid-row: 1;
  z-index: 8;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
}

.invBox {
}

.anItemBox {
  grid-column: 1;
  grid-row: 1;
  position: relative;
}

.anItemBoxClaimed {
  z-index: 8;
  grid-column: 1;
  grid-row: 1;
}

.invPanel {
  background-color: rgb(139, 117, 105);
  overflow: hidden !important;
}

.gridItemHolder {
  display: grid;
}

.colItemImage {
  opacity: 0.5;
  filter: brightness(0.04) contrast(1.8) grayscale(1);
}
